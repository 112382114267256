<template>
  <div>
    <div>
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-12">
            <div class="login-card" style="display: block;">
              <div>
                <div>
                  <a class="logo">
                    <img
                      class="img-fluid for-light"
                      src="@/assets/images/PSICOMETRIX-logo.jpg"
                      alt="looginpage"
                      width="170px"
                    />
                    <img
                      class="img-fluid for-dark"
                      src="@/assets/images/logo/logo_dark.png"
                      alt="looginpage"
                    />
                  </a>
                </div>

                <div class="login-main login-form-card shadow">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="alert alert-success font-unv" role="alert">
                        <p>
                          {{text}} <b>{{code}}</b> {{text_extend_two}}
                        </p>
                        <p class="mt-2">
                         <strong>{{text_extend}}</strong> 
                        </p>
                        <hr />
                        <p class="mb-0">
                          {{signature}}
                        </p>
                      </div>
                    </div>

                    <p class="mt-4 mb-0">
                        ¿Quieres realizar un nuevo registro?

                        <router-link class="ml-2" tag="a" to="/">
                        <strong>Click aqui</strong>  
                        </router-link>
                      </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Message",
  data() {
    return {
      title: '',
      text: '',
      text_extend: 'Pronto recibirás las instrucciones para realizar la evaluación.',
      text_extend_two: '',
      code: '',
      signature: 'PSICOMETRIX CHILE SPA'
    };
  },
  methods: {
    setmessage(code, order){
      if(code === '1005'){
        this.title = '¡Orden ya pagada!';
        this.text = `Le informamos que la orden:`
        this.code = order;
        this.text_extend_two = ``;
        this.text_extend = `Ya fue procesado el pago.`;
      }
      else if(code === '1001'){
        this.title = '¡Pago existoso!';
        this.text = `Le informamos que el pago de la orden:`
        this.code = order;
        this.text_extend_two = `,ha sido procesado con éxito.`;
        this.text_extend = `Pronto recibirás las instrucciones para realizar la evaluación. Gracias por su compra.`;
      }
    }
  },
  created() {
    const code = this.$route.params.code;
    const order = this.$route.params.order;
    this.setmessage(code, order);
  },
};
</script>

<style scoped>

.alert-success {
  background-color: #dfdfdf !important;
  border-color: #51bb25;
  color: #51bb25;
}
</style>